import Contact from "../components/contact"

const ContactPage: React.FunctionComponent<any> = () => {
    return (
        <div className="Centered-componant">
            <Contact></Contact>
        </div>
    )
}

export default ContactPage;