import { Col, Container, Row, Image } from "react-bootstrap";
import { useTranslation } from "react-i18next"

interface PartElement {
    title: string,
    text: string,
    image?: any
}

const Part: React.FunctionComponent<any> = ({title, text, image}: PartElement) => {
    const { t } = useTranslation();
    const renderImage = (image: string) => {
        if (title) {
            return <div  style={{marginTop: "auto"}}><Image fluid src={image} rounded /></div>
        } else {
            return <></>
        }
    }
    return (
        <div style={{padding: "1rem", height: "100%", display: "flex", flexDirection: "column"}}>
            <h2 className="Title-primary Multiline-text">{t(title)}</h2>
            <p className="Text-dark Multiline-text">{t(text)}</p>
            {renderImage(image)}
        </div>
    )

}

const Tryptych: React.FunctionComponent<any> = ({title = null, left, middle, right}) => {
    const { t } = useTranslation();
    const renderTitle = (title: string) => {
        if (title) {
            return <h1 className="Title-primary">{t(title)}</h1>
        } else {
            return <></>
        }
    }
    return (
        <>
            {renderTitle(title)}
            <Container fluid>
                <Row>
                    <Col xs={12} lg={4}>
                        <Part {...left}/>
                    </Col>
                    <Col xs={12} lg={4}>
                        <Part {...middle}/>
                    </Col>
                    <Col xs={12} lg={4}>
                        <Part {...right}/>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Tryptych;