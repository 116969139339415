import { FunctionComponent } from "react";
import { Badge, Col, Image, Row } from "react-bootstrap";
import TitleTextBlock from "./title-text-block";
import { useNavigate } from "react-router-dom";



const ArticleShort: FunctionComponent<any> = ({articleShort, setSelectedTag}) => {
    
    
    const selectTag = (event: any, selectedTag: string) => {
        console.log(event);
        event.stopPropagation();
        console.log(selectedTag);
        setSelectedTag(selectedTag)
    }

    const navigate = useNavigate();

    function handleClick() {
        navigate(`/blog/${articleShort.route}`);
    }

    const badges = articleShort.tags.map((x: string, i: number) => <Badge key={i} onClick={(event) => selectTag(event, x)} className="p-2 m-1" style={{cursor: "pointer"}} bg="secondary">{x}</Badge>)
    return (
        <div onClick={handleClick} style={{cursor: "pointer"}}>
            <Row>
                <Col xs={12} md={4}>
                    <Image style={{display: "block", height: "100%", width: "100%"}} src={articleShort.image}></Image> 
                </Col>
                <Col xs={12} md={8}>
                    <div className="Centered-vertically">
                        <TitleTextBlock title={articleShort.title} text={articleShort.short}></TitleTextBlock>
                        {badges}
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default ArticleShort;
