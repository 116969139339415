import { t } from "i18next"
import TitleTextBlock from "./title-text-block"
import PhotoTryptych from "./photo-tryptych"


const PortfolioImage: React.FunctionComponent<any> = ({title, text, imageLeft, imageMiddle, imageRight}) => {
    return (
        <div className="m-auto pt-5" style={{maxWidth: "90vw"}}>
            
            <h1 className="Title-primary">{t(title)}</h1>
            <TitleTextBlock text={text}/>
            <PhotoTryptych imageLeft={imageLeft} imageMiddle={imageMiddle} imageRight={imageRight} />
        </div>
    )
}

export default PortfolioImage;