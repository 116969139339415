import { FunctionComponent } from "react";
import Welcome from "../components/welcome";
import { useTranslation } from "react-i18next";
import ExternalLink from "../core/external-link";
import SocialNetworks from "../core/social-networks";
const LinksPage: FunctionComponent<any> = () => {
    const { t } = useTranslation();
    return (
        <div className="App-header">
            <div className="Welcome-content">
                <div className="mb-4"><a href="/"><h1 className="Title-primary text-center">{t("links.site")}</h1></a></div>
                <div className="mb-4"><a href="/goodies"><h1 className="Title-primary text-center">{t("links.goodies")}</h1></a></div>
                <div className="mb-4"><ExternalLink link="http://luciedoret.bigcartel.com/"><h1 className="Title-primary text-center">{t("links.shop")}</h1></ExternalLink></div>
                <div style={{textAlign: "center"}}><SocialNetworks logoSize="lg" className="Logo-list"></SocialNetworks></div>
            </div>
        </div>
        )
}

export default LinksPage;