import { faInstagram, faLinkedin, faPinterest } from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Col } from "react-bootstrap"
import ExternalLink from "./external-link"

const SocialNetwork: React.FunctionComponent<any> = ({className, icon, link, size}) => {
    return (
        <ExternalLink link={link}><FontAwesomeIcon className={className} icon={icon} size={size}/></ExternalLink>
    )
}

const SocialNetworks: React.FunctionComponent<any> = ({logoSize = "2x", className}) => {
    const instagramLink = "https://www.instagram.com/lucie_doret/";
    const linkedinLink = "https://www.linkedin.com/company/lucie-doret/";
    const pinterestLink = "https://www.pinterest.fr/luciedrt/";
    return (
        <>
            <Col xs={12}>
                <SocialNetwork className={className} icon={faInstagram} link={instagramLink} size={logoSize}/>
                <SocialNetwork className={className} icon={faLinkedin} link={linkedinLink} size={logoSize}/>
                <SocialNetwork className={className} icon={faPinterest} link={pinterestLink} size={logoSize}/>
            </Col>
        </>
    )
}

export default SocialNetworks;