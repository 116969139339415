import { Container, Row, Col, Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const PhotoDuo: React.FunctionComponent<any> = ({title = null, imageLeft, imageRight}) => {
    const { t } = useTranslation();
    const renderTitle = (title: string) => {
        if (title) {
            return <h1 className="Title-primary">{t(title)}</h1>
        } else {
            return <></>
        }
    }
    return (
        <div className="pt-3">
            {renderTitle(title)}
            <Container fluid>
                <Row>
                    <Col xs={12} md={6}>
                        <Image fluid src={imageLeft} className="mt-3" rounded />
                    </Col>
                    <Col xs={12} md={6}>
                        <Image fluid src={imageRight} className="mt-3" rounded />
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default PhotoDuo;