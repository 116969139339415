import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Contact from "../components/contact";
import Clients from "../components/clients";
import About from "../components/about";
import Realisations from "../components/realisations";
import Welcome from "../components/welcome";
import TitleTextBlock from "../components/title-text-block";
import Tryptych from "../components/tryptych";
import { useRef } from "react";
import React from "react";

// Images

function HomePage() {
    const { t } = useTranslation();

    const myRef = React.useRef(document.createElement("div"));

    const executeScroll = () => myRef.current.scrollIntoView();
    return (
        <>
            <header>
                <Welcome textTop="header-title.top" textBottom="header-title.bottom"/>
                <i className="Arrow down" onClick={executeScroll}></i>
            </header>
            <section ref={myRef} className="Background-light">
                <div className="w-75 m-auto ">
                    <TitleTextBlock title="concept.title" text="concept.text"/>
                    <div className="pt-5">
                        <TitleTextBlock title="concept.title2" text="concept.text2"/>
                    </div>
                </div>
            </section>
            <section>
                <div className="w-75 m-auto">
                    <Tryptych title="services.title" 
                              left={{title: "services.presentation.left.title", text: "services.presentation.left.text"}}
                              middle={{title: "services.presentation.middle.title", text: "services.presentation.middle.text"}}
                              right={{title: "services.presentation.right.title", text: "services.presentation.right.text"}}/>
                    <Button variant='light' href="/services" className="Button-primary Text-light m-auto">{t("button.services")}</Button>
                    <div className="pt-5">
                        <Clients></Clients>
                    </div>
                </div>
            </section>
            <section className="Background-light">
                <About></About>
            </section>
            <section className="w-75 m-auto">
                <Realisations></Realisations>
            </section>
            <section className="Background-light">
                <div className="w-75 m-auto ">
                    <TitleTextBlock title="advertising.title" text="advertising.text"/>
                </div>
            </section>
            <section>
                <Contact></Contact>
            </section>

        </>
    )
  }

export default HomePage;