import { Container, Image, Row, Col } from "react-bootstrap";


const RowPhotoBlock: React.FunctionComponent<any> = ({block, image, imageRight = false, verticallyCentered = false}) => {
    const renderImage = (image: any) => {
        return (
            <Col lg={6}>
                <div className="Centered-vertically">
                    <Image fluid src={image} rounded />
                </div>
            </Col>
        )
    }
    const renderBlock = (block: any) => {
        if (verticallyCentered) {
            return (
                <div className="Centered-vertically">
                    {block}
                </div>
            )
        }
        return (
            <>{block}</>
        )
    }
    const renderContent = (block: any, image: any) => {
        if (imageRight) {
            return (
                <Row>
                    <Col lg={6}>
                        {renderBlock(block)}
                    </Col>
                    {renderImage(image)}            
                </Row>
            )
        } 
        return (
            <Row>
                {renderImage(image)}
                <Col lg={6}>                
                    {renderBlock(block)}
                </Col>
            </Row>
        )
    }
    return (
        <Container fluid>
            {renderContent(block, image)}
        </Container>
    )
}

export default RowPhotoBlock;