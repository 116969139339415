import { useTranslation } from "react-i18next"
import { Col, Image, Row } from 'react-bootstrap'
import React from "react";

//images
import imageRealisation0 from '../public/images/Accueil/01-Structural.png';
import imageRealisation1 from '../public/images/Accueil/02-Total.png';
import imageRealisation2 from '../public/images/Accueil/03-Mairie.png';
import imageRealisation3 from '../public/images/Accueil/04-Niort.png';
import imageRealisation4 from '../public/images/Accueil/05-Mariage.png';
import imageRealisation5 from '../public/images/Accueil/06-Motion.png';

const Realisation: React.FunctionComponent<any> = ({name, text}) => {
    const { t } = useTranslation();
    return (
        <div className="Realisation-container">
            <Image src={name} className="Realisation-image"></Image>
            <div className="Realisation-overlay" >
                <div className="Realisation-short"><p className="Realisation-text Text-light">{t(text)}</p></div>
            </div>
        </div>
    )
}

const Realisations: React.FunctionComponent = () => {
    const { t } = useTranslation();
    return (
        <div>
            <h1 className="Title-primary pb-5">{t('realisations.title')}</h1>
            <Row style={{maxWidth: "100vw"}}>
                <Col className="p-0" xs={12} md={6} lg={4}>
                    <Realisation name={imageRealisation0} text="realisations.0"></Realisation>
                </Col>
                <Col className="p-0" xs={12} md={6} lg={4}>
                    <Realisation name={imageRealisation1} text="realisations.1"></Realisation>
                </Col>
                <Col className="p-0" xs={12} md={6} lg={4}>
                    <Realisation name={imageRealisation2} text="realisations.2"></Realisation>
                </Col>
                <Col className="p-0" xs={12} md={6} lg={4}>
                    <Realisation name={imageRealisation3} text="realisations.3"></Realisation>
                </Col>
                <Col className="p-0" xs={12} md={6} lg={4}>
                    <Realisation name={imageRealisation4} text="realisations.4"></Realisation>
                </Col>
                <Col className="p-0" xs={12} md={6} lg={4}>
                    <Realisation name={imageRealisation5} text="realisations.5"></Realisation>
                </Col>
            </Row>
        </div>
    )
}

export default Realisations;