import { useTranslation } from "react-i18next"


const TitleTextBlock: React.FunctionComponent<any> = ({title = null, text}) => {
    const { t } = useTranslation();
    const renderTitle = (title: string) => {
        if (title) {
            return <h1 className="Title-primary">{t(title)}</h1>
        } else {
            return <></>
        }
    }
    return (
        <div>
            {renderTitle(title)}
            <p className="Multiline-text">{t(text)}</p>
        </div>
    )
}

export default TitleTextBlock;