import './App.scss';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import CustomNavbar from './core/navbar';
import Footer from './core/footer';
import './core/i18n'

import HomePage from './pages/home-page';
import ContactPage from './pages/contact-page';
import AboutPage from './pages/about-page';
import ServicesPage from './pages/services-page';
import PortfolioPage from './pages/portfolio-page';
import Page404 from './pages/page-404';
import GoodiesPage from './pages/goodies-page';
import BlogPage from './pages/blog-page';
import Article from './components/article';
import LinksPage from './pages/links-page';


function App() {
  return (
    <BrowserRouter>
      <CustomNavbar/>
      <div className='Content'>
        <Routes>
          <Route path="/" element={<HomePage/>} />
          <Route path="/services" element={<ServicesPage/>} />
          <Route path="/portfolio" element={<PortfolioPage/>} />
          <Route path="/blog" element={<BlogPage/>} />
          <Route path="/blog/:id" element={<Article/>}/>
          <Route path="/links" element={<LinksPage/>} />
          <Route path="/about" element={<AboutPage/>} />
          <Route path="/contact" element={<ContactPage/>} />
          <Route path="/goodies" element={<GoodiesPage/>} />
          <Route path="*" element={<Page404 />}/>
        </Routes>
      </div>
      <footer className='Footer'>
        <Footer></Footer>
      </footer>
      
    </BrowserRouter>
  );
}

export default App;
