import { Container, Row, Col, Image } from "react-bootstrap";
import aboutImage from '../public/images/lucie-doret.png';
import SocialNetworks from "../core/social-networks";
import TitleTextBlock from "./title-text-block";

const About: React.FunctionComponent = () => {
    return (
        <div className="w-75 m-auto Standard-vertical-padding Text-centered">

            <Container fluid>
                <Row>
                    <Col lg={8} className="order-lg-2">
                        <div className="Centered-vertically">
                            <TitleTextBlock title="about.title" text="about.text"/>
                            <SocialNetworks className="Logo-list"/>
                        </div>
                    </Col>
                    <Col lg={4} className="order-lg-1">
                        <Image fluid style={{height: "inherit"}} src={aboutImage} rounded />
                    </Col>
                    
                </Row>
            </Container>
        </div>
    )
}

export default About;