import SocialNetworks from "./social-networks";


const Footer: React.FunctionComponent = () => {
    return (
        <>
            <div className="Background-primary Text-light pb-3 pt-2" style={{textAlign: "center"}}>
                <SocialNetworks logoSize="lg" className="Logo-list-light"></SocialNetworks>
                © Copyright Lucie Doret - Charlélie Morineau
            </div>
            
        </>
    )
}

export default Footer;