import { FunctionComponent } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';

const ContactModal: FunctionComponent<any> = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        >
            <Modal.Header closeButton className='Background-light'>
                <Modal.Title id="contained-modal-title-vcenter">
                {t(props.title)}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='Background-light'>
                <p>
                {t(props.text)}
                </p>
            </Modal.Body>
        </Modal>
    </>
  );
}

export default ContactModal;