import { createRef, useState } from "react";
import { Col, Form, FormGroup, Row, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";
import ContactModal from "./contact-modal";
import * as formik from 'formik';
import * as yup from 'yup';

const ContactForm: React.FunctionComponent = () => {
    const { t } = useTranslation()
    const { Formik } = formik;

    const [modalTitle, setModalTitle] = useState("");
    const [modalText, setModalText] = useState("");

    const [modalShow, setModalShow] = useState(false);
    const [validated, setValidated] = useState(false);
    const [recaptchaValue, setRecapchaValue]: any = useState(null);

    const schema = yup.object().shape({
      name: yup.string().trim().required(),
      email: yup.string().email().required(),
      message: yup.string().trim().required()
    });

    const handleSubmit = (event: any, {setSubmitting, setErrors, setStatus, resetForm, setTouched}: any) => {
      if (!(recaptchaValue && recaptchaValue.trim() != "")){
        return;
      }
      setValidated(true)
      event.recaptchaValue = recaptchaValue;
      const mail = JSON.stringify(event)
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: mail
      };
      fetch('mailer.php', requestOptions)
          .then(response => {
            if (response.status === 200){
              setModalTitle("contact.modal.success.title");
              setModalText("contact.modal.success.text");
              setModalShow(true)
              resetForm();
              setStatus({success: true})
              setTouched({});
            } else {
              setModalTitle("contact.modal.failure.title");
              setModalText("contact.modal.failure.text");
              setStatus({success: false})
              setSubmitting(false)
              setErrors({submit: "Oops something wrong..."})
              setModalShow(true)
            }
          });  
    };
  

    return (
      <div className="Custom-form">
        <Formik
          validationSchema={schema}
          onSubmit={handleSubmit}
          
          initialValues={{
            name: '',
            email: '',
            message: ''
          }}
        >
          {({ handleSubmit, handleChange, values, touched, errors }) => (
        <Form  noValidate validated={validated} onSubmit={handleSubmit}>
          <Row className="mb-3">
            <FormGroup  as={Col} className="mb-3">
              <Form.Label className="text-uppercase Title-primary">{t('contact.form.name')}</Form.Label>
              <Form.Control 
                name="name" 
                className="Background-light" 
                type="text" 
                onChange={handleChange}
                placeholder={t('contact.form.name-placeholder')} 
                value={values.name}
                isValid={touched.name && !errors.name}
                isInvalid={!!errors.name}
                />
              <Form.Control.Feedback></Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                </Form.Control.Feedback>
            </FormGroup>
            <FormGroup as={Col} className="mb-3">
              <Form.Label className="text-uppercase Title-primary">{t('contact.form.email')}</Form.Label>
              <Form.Control 
                className="Background-light" 
                type="email"
                name="email" 
                onChange={handleChange}
                value={values.email}
                isValid={touched.message && !errors.email}
                isInvalid={!!errors.email}
                placeholder={t('contact.form.email-placeholder')} />
            </FormGroup>
            <Form.Control.Feedback></Form.Control.Feedback>
            <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
          </Row>
          <Form.Group className="mb-3">
            <Form.Label className="text-uppercase Title-primary">{t('contact.form.message')}</Form.Label>
            <Form.Control 
              as="textarea" 
              className="Background-light Contact-textarea" 
              placeholder={t('contact.form.message-placeholder')}
              name="message"
              onChange={handleChange}
              value={values.message}
              isValid={touched.message && !errors.message}
              isInvalid={!!errors.message}
              />
          </Form.Group>
          
          <div style={{width: '100%', display: 'inline-flex', justifyContent: 'center'}}>
            <ReCAPTCHA 
                  sitekey="6LcO_BUhAAAAADPGYMsUaB8qsZ4GqCzOub_HHiPD"
                  onChange={(value) => {
                    setRecapchaValue(value);
                  }}
                />
                {(recaptchaValue && recaptchaValue.trim() !== '') &&
                <div style={{marginLeft: "5px"}}>
                  <Button variant='light' className=" Centered-vertically Button-primary Text-light" type="submit">{t('contact.button')}</Button>
                  </div>
                }
          </div>
          

          <ContactModal
            show={modalShow}
            size="normal"
            onHide={() => setModalShow(false)}
            title={modalTitle}
            text={modalText}
            />
        </Form>
        )}
        
        </Formik>
        
      </div>
    )
  }

const Contact: React.FunctionComponent = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="w-75 m-auto">
        <h1 className="Title-primary p-5" style={{textAlign: "center"}}>{t('contact.title')}</h1>
        <ContactForm></ContactForm>
      </div>
    </>
  )
}

export default Contact;