import { FunctionComponent, useEffect, useState } from "react";
import { useParams } from 'react-router';
import { Badge, Image } from "react-bootstrap";
import TitleTextBlock from "./title-text-block";
import RowPhotoBlock from "./row-photo-block";
import PhotoDuo from "./photo-duo";
import PhotoTryptych from "./photo-tryptych";
import { useNavigate } from "react-router-dom";


const Subtitle: FunctionComponent<any> = ({text}) => {
    return (
        <h2 className="Title-primary">{text}</h2>
    )
}

const WhiteSpace: FunctionComponent<any> = ({size}) => {
    console.log(size)
    return (
        <div style={{width: "100%", display: "block", minHeight: size}}></div>
    )
}

const Article: FunctionComponent<any> = () => {
    const { id } = useParams();
    const [datas, setDatas] = useState();
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        fetchData();
    }, []);
    
    const fetchData = async () => {
        try {
            const response = await fetch(`/article.php?article=${id}`);
            // const response = await fetch(`http://localhost:4000/luciedoret.fr/backend/src/article.php?article=${id}`);
            const jsonData = await response.json();
            setDatas(jsonData);
            setLoaded(true);
            console.log(jsonData)
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const renderSwitch: any = (content: any, idx: number) => {
        switch(content['type']) {
            case 'subtitle':
                return <Subtitle key={idx} text={content['datas']['text']}/>;
            case 'image-text':
                return <RowPhotoBlock key={idx} image={content['datas']['image']} block={<TitleTextBlock text={content['datas']['text']} />}/>
            case 'image-text-title':
                return <RowPhotoBlock key={idx} image={content['datas']['image']} block={<TitleTextBlock title={content['datas']['title']} text={content['datas']['text']} />}/>
            case 'photo-duo':
                return <PhotoDuo key={idx} imageLeft={content['datas']['image-left']} imageRight={content['datas']['image-right']} />
            case 'photo-trio':
                return <PhotoTryptych key={idx} imageLeft={content['datas']['image-left']} imageMiddle={content['datas']['image-middle']} imageRight={content['datas']['image-right']} />
            case 'image':
                return <Image key={idx} fluid style={{width: "100%"}} src={content['datas']['image']} className="mt-3" rounded />
            case 'whitespace':
                return <WhiteSpace size={content['datas']['size']}/>
            case 'paragraphe':
                return <p className="Multiline-text">{content['datas']['text']}</p>
            default:
                return <div key={idx}></div>;
        }
    }

    const navigate = useNavigate();

    function handleClick(route: string) {
        navigate(`/blog/${route}`);
        navigate(0);
    }

    const renderContent: any = () => {
        const articleData = datas!['article'];
        const content: any[] = articleData!['content'];
        const badges: any[] = articleData!['tags'];
        const date: any = articleData!['date'];
        if (content) {
            return (
                <div>
                    <h1 className="Title-primary">{articleData!['title']}</h1>
                    <div style={{display: 'inline-block'}}>
                        <div style={{display: 'inline'}}>{date}</div>
                        <div style={{display: 'inline', marginLeft: "2rem"}}>{badges.map((x: string, i: number) => <Badge key={i} className="p-2 m-1" bg="secondary">{x}</Badge>)}</div>
                    </div>
                    {
                        content.map((x,i) => <div key={i} className="mt-3 mb-3">{renderSwitch(x, i)}</div>)
                    }
                    <div className="mt-5 mb-5" style={{borderTop: "1px solid black",borderBottom: "1px solid black"}}>
                        {(datas!['prevArticle'])? <div className="pl-5 w-50" style={{display: "inline"}} onClick={() => handleClick(datas!['prevArticle']['route'])}>
                            
                            <p className="Primary-text" style={{display: "inline"}}><i className="Arrow-small left"></i>{datas!['prevArticle']['title']}</p>
                        </div>: <div className="pl-5 w-50" style={{display: "inline"}}><p style={{display: "inline"}}>&nbsp;</p></div>}
                        {(datas!['nextArticle'])? <div className=" w-50" style={{display: "inline"}} onClick={() => handleClick(datas!['nextArticle']['route'])}>
                            <p className="Primary-text" style={{display: "inline", float: "right"}}>{datas!['nextArticle']['title']}<i className="Arrow-small right"></i></p>
                        </div>: <></>}
                    </div>
                </div>
            )
        } else {
            return <></>
        }
    };

   
    return (
        <div className="m-auto w-75 Under-navbar">
            {
                (loaded)? renderContent() : 
                <div>Loading</div>
            }
        </div>
    )
}

export default Article;