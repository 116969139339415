import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import TitleTextBlock from "../components/title-text-block";
import Tryptych from "../components/tryptych";
import RowPhotoBlock from "../components/row-photo-block";
import guidePhotoPdf from '../public/download/guide-photos-2023-lucie-doret.pdf';
import ExternalLink from "../core/external-link";

//Images
import serviceLeft from '../public/images/Services/Communication.png'
import serviceMiddle from '../public/images/Services/Illustration.png'
import serviceRight from '../public/images/Services/Motion.png'
import servicePrivateIndividual from '../public/images/Services/Particulier.png'

const ServicesPage: React.FunctionComponent = () => {
    const { t } = useTranslation();
    return (
        <>
            <section>
                <div className="w-75 m-auto pb-5 pt-5">
                    <TitleTextBlock title="services.title" text="services.text"/>
                </div>
                <div className="w-75 m-auto">
                    <Tryptych
                              left={{title: "services.presentation.left.title", text: "services.presentation.left.text", image: serviceLeft}}
                              middle={{title: "services.presentation.middle.title", text: "services.presentation.middle.text", image: serviceMiddle}}
                              right={{title: "services.presentation.right.title", text: "services.presentation.right.text", image: serviceRight}}/>
                </div>
                <div className="w-75 m-auto pt-5">
                    <TitleTextBlock title="advertising.business.title" text="advertising.business.text"/>
                    <Button variant='light' href="/contact" className="Button-primary Text-light">{t('button.contacts')}</Button>
                </div>
                
            </section>
            <section className="Background-light">
                <div className="w-75 m-auto pt-3">
                    <h1 className="Title-primary pb-4">{t("services.private-individual.title")}</h1>
                <RowPhotoBlock block={<>
                    <TitleTextBlock text="services.private-individual.text"/>
                    <ExternalLink link={guidePhotoPdf}><Button variant='light' className="Button-primary Text-light">{t('button.download-photo-guide')}</Button></ExternalLink></>
                } image={servicePrivateIndividual} imageRight={true}/>      
                </div>
                <div className="w-75 m-auto pt-5">
                    <TitleTextBlock title="advertising.private-individual.title" text="advertising.private-individual.text"/>
                    <Button variant='light' href="/contact" className="Button-primary Text-light">{t('button.contacts')}</Button>
                </div>
            </section>

        </>
    )
  }

export default ServicesPage;