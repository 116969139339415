import { FunctionComponent } from "react";


const Page404: FunctionComponent = () => {
    return (
        <div  className="Not-found-header">
        </div>
    )
}


export default Page404;