import About from "../components/about"

const AboutPage: React.FunctionComponent<any> = () => {
    return (
        <div className="Centered-componant">
            <About></About>
        </div>
    )
}

export default AboutPage;