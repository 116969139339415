import { useTranslation } from "react-i18next"
import TitleTextBlock from "./title-text-block"
import YoutubeEmbed from "./youtube-embeded"
import { Col, Row } from "react-bootstrap"

const PortfolioVideo: React.FunctionComponent<any> = ({title, text, embedVideoId}) => {
    const { t } = useTranslation()
    return (
        <div className="m-auto pt-5" style={{maxWidth: "90vw"}}>
            
            <h1 className="Title-primary">{t(title)}</h1>
            <Row>
                <Col md={4}>
                    <div className="Centered-vertically">
                        <TitleTextBlock text={text}/> 
                    </div>
                </Col>
                <Col>
                    <YoutubeEmbed embedId={embedVideoId}/>
                </Col>
            </Row>
        </div>
    )
}

export default PortfolioVideo;