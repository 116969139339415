import TitleTextBlock from "../components/title-text-block";
import Slideshow from "../components/slideshow";
import PortfolioImage from "../components/portfolio-image";
import PortfolioVideo from "../components/portfolio-video";
//images
//Structural
import project0 from '../public/images/Portfolio/01-Structural.png'
import project0Left from '../public/images/Portfolio/Structural-01.png'
import project0Middle from '../public/images/Portfolio/Structural-02.png'
import project0Right from '../public/images/Portfolio/Structural-03.png'
//Total
import project1 from '../public/images/Portfolio/02-Total.png'
import project1Left from '../public/images/Portfolio/Total-01.png'
import project1Middle from '../public/images/Portfolio/Total-02.png'
import project1Right from '../public/images/Portfolio/Total-03.png'
//Mairies
import project2 from '../public/images/Portfolio/03-Mairie.png'
import project2Left from '../public/images/Portfolio/Mairie-01.png'
import project2Middle from '../public/images/Portfolio/Mairie-02.png'
import project2Right from '../public/images/Portfolio/Mairie-03.png'
//Anastylose
import project3 from '../public/images/Portfolio/04-Anastylose.png'
import project3Left from '../public/images/Portfolio/Anastylose-01.png'
import project3Middle from '../public/images/Portfolio/Anastylose-02.png'
import project3Right from '../public/images/Portfolio/Anastylose-03.png'
//Niort
import project4 from '../public/images/Portfolio/05-Niort.png'
import project4Left from '../public/images/Portfolio/Niort-01.png'
import project4Middle from '../public/images/Portfolio/Niort-02.png'
import project4Right from '../public/images/Portfolio/Niort-03.png'
//Mariage
import project5 from '../public/images/Portfolio/06-Mariage.png'
import project5Left from '../public/images/Portfolio/Mariage-01.png'
import project5Middle from '../public/images/Portfolio/Mariage-02.png'
import project5Right from '../public/images/Portfolio/Mariage-03.png'
//Projet XXXXXXXX
import project6 from '../public/images/Portfolio/07-Carte.png'
import project6Left from '../public/images/Portfolio/Cartes-01.png'
import project6Middle from '../public/images/Portfolio/Cartes-02.png'
import project6Right from '../public/images/Portfolio/Cartes-03.png'
//Maisons
import project7 from '../public/images/Portfolio/08-Maison.png'
import project7Left from '../public/images/Portfolio/Maison-01.png'
import project7Middle from '../public/images/Portfolio/Maison-02.png'
import project7Right from '../public/images/Portfolio/Maison-03.jpg'
//Motion
import project8 from '../public/images/Portfolio/09-Motion.png'
const PortfolioPage: React.FunctionComponent = () => {

    const projects = [
        {title: "Project Test", id: 0, image: project0, component: <PortfolioImage text="project.0.text" imageLeft={project0Left} imageMiddle={project0Middle} imageRight={project0Right} title="project.0.title"></PortfolioImage>},
        {title: "Project Test", id: 1, image: project1, component: <PortfolioImage text="project.1.text" imageLeft={project1Left} imageMiddle={project1Middle} imageRight={project1Right} title="project.1.title"></PortfolioImage>},
        {title: "Project Test", id: 2, image: project2, component: <PortfolioImage text="project.2.text" imageLeft={project2Left} imageMiddle={project2Middle} imageRight={project2Right} title="project.2.title"></PortfolioImage>},
        {title: "Project Test", id: 3, image: project3, component: <PortfolioImage text="project.3.text" imageLeft={project3Left} imageMiddle={project3Middle} imageRight={project3Right} title="project.3.title"></PortfolioImage>},
        {title: "Project Test", id: 4, image: project4, component: <PortfolioImage text="project.4.text" imageLeft={project4Left} imageMiddle={project4Middle} imageRight={project4Right} title="project.4.title"></PortfolioImage>},
        {title: "Project Test", id: 5, image: project5, component: <PortfolioImage text="project.5.text" imageLeft={project5Left} imageMiddle={project5Middle} imageRight={project5Right} title="project.5.title"></PortfolioImage>},
        {title: "Project Test", id: 6, image: project6, component: <PortfolioImage text="project.6.text" imageLeft={project6Left} imageMiddle={project6Middle} imageRight={project6Right} title="project.6.title"></PortfolioImage>},
        {title: "Project Test", id: 7, image: project7, component: <PortfolioImage text="project.7.text" imageLeft={project7Left} imageMiddle={project7Middle} imageRight={project7Right} title="project.7.title"></PortfolioImage>},
        {title: "Project Test", id: 8, image: project8, component: <PortfolioVideo title="project.8.title" text="project.8.text" embedVideoId="8gahrn4G9ks"/>}
    ]

    return (
        <>
            <section>
                <div className="w-75 m-auto">
                    <div className="pb-5 pt-5">
                        <TitleTextBlock title="portfolio.title" text="portfolio.short"/>
                    </div>
                    
                    <Slideshow elements={projects}></Slideshow>
                </div>
            </section>

        </>
    )
  }

export default PortfolioPage;