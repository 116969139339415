import { Container, Row, Col, Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const PhotoTryptych: React.FunctionComponent<any> = ({title = null, imageLeft, imageMiddle, imageRight}) => {
    const { t } = useTranslation();
    const renderTitle = (title: string) => {
        if (title) {
            return <h1 className="Title-primary">{t(title)}</h1>
        } else {
            return <></>
        }
    }
    return (
        <div className="pt-3">
            {renderTitle(title)}
            <Container fluid>
                <Row>
                    <Col xs={12} md={6} lg={4}>
                        <Image fluid src={imageLeft} className="mt-3" rounded />
                    </Col>
                    <Col xs={12} md={6} lg={4}>
                        <Image fluid src={imageMiddle} className="mt-3" rounded />
                    </Col>
                    <Col xs={{ span: 12, offset: 0}} md={{ span: 6, offset: 3 }} lg={{ span: 4, offset: 0}}>
                        <Image fluid src={imageRight} className="mt-3" rounded />
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default PhotoTryptych;