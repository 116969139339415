import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationFR from "../public/locales/fr/translation.json"
import translationEN from "../public/locales/en/translation.json"

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: translationEN
      },
      fr: {
        translation: translationFR
      }
    },
    lng: "fr", // if you're using a language detector, do not define the lng option
    fallbackLng: "en",

    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  });

export default i18n;