import { useEffect, useState } from "react";
import { Navbar, Container, Nav } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function CustomNavbar() {
    const { t } = useTranslation();

    const [colorChange, setColorchange] = useState(false);
    const changeNavbarColor = () => {
        if (window.scrollY >= 80) {
            setColorchange(true);
        } else {
            setColorchange(false);
        }
    };

    useEffect(() => {
        changeNavbarColor()
        window.addEventListener("scroll", changeNavbarColor)
      })
    return (
      <Navbar fixed="top" expand="lg" className={
        colorChange
            ? "Background-light"
            : "Background-normal"
    }>
        <Container>
          <Navbar.Brand className="Title-secondary" href="/">{t('menu.brand')}</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link className="Title-secondary Menu-link" href="/">{t('menu.home')}</Nav.Link>
              <Nav.Link className="Title-secondary Menu-link" href="/services">{t('menu.services')}</Nav.Link>
              <Nav.Link className="Title-secondary Menu-link" href="/portfolio">{t('menu.portfolio')}</Nav.Link>
              <Nav.Link className="Title-secondary Menu-link" href="/blog">{t('menu.blog')}</Nav.Link>
              <Nav.Link className="Title-secondary Menu-link" href="/about">{t('menu.about')}</Nav.Link>
              <Nav.Link className="Title-secondary Menu-link" href="/contact">{t('menu.contact')}</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    )
  }

  export default CustomNavbar;