import React, { useEffect, useRef, useState } from "react";
import { Image } from "react-bootstrap";

import { useWindowWidth } from "@react-hook/window-size";

const delay = 8000;

const Slideshow: React.FunctionComponent<any> = ({elements}) => {
  const windowWidth = useWindowWidth()
  const [nbVisibleElements, setNbVisibleElements] = useState<number>(3)
  const [index, setIndex] = useState(0);
  const timeoutRef = useRef<ReturnType<typeof setInterval> | null>(null)
  const [printedComponent, setPrintedComponent] = React.useState(elements[0])

  useEffect(() => {
    if(windowWidth < 720)
      setNbVisibleElements(1);
    else if(windowWidth >= 720 && windowWidth < 992)
      setNbVisibleElements(2);
    else
      setNbVisibleElements(3);
  }, [windowWidth])

  useEffect(() => {
    if(Math.ceil(elements.length / nbVisibleElements) < (index + 1))
      setIndex(Math.ceil(elements.length / nbVisibleElements))
  }, [nbVisibleElements])

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  const [touchStart, setTouchStart] = React.useState(0);
  const [touchEnd, setTouchEnd] = React.useState(0);

  function handleTouchStart(e: any) {
      setTouchStart(e.targetTouches[0].clientX);
  }

  function handleTouchMove(e: any) {
      setTouchEnd(e.targetTouches[0].clientX);
  }

  function handleTouchEnd() {
      
    if (touchEnd > 10){
      if (touchStart - touchEnd > 150) {
          // do your stuff here for left swipe
          setIndex((prevIndex) =>
          prevIndex === Array.from({length: Math.ceil(elements.length / nbVisibleElements)}, (x, i) => i).length - 1 ? 0 : prevIndex + 1
        )
      }

      if (touchStart - touchEnd < -150) {
          // do your stuff here for right swipe
          setIndex((prevIndex) =>
          prevIndex === 0 ? Array.from({length: Math.ceil(elements.length / nbVisibleElements)}, (x, i) => i).length - 1 : prevIndex - 1
        )
      }
    }
    setTouchStart(0)
    setTouchEnd(0)
  }

  React.useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () => {
        setIndex((prevIndex) =>
          prevIndex === Array.from({length: Math.ceil(elements.length / nbVisibleElements)}, (x, i) => i).length - 1 ? 0 : prevIndex + 1
        )
      },
      delay
    );

    return () => {
      resetTimeout();
    };
  }, [index]);

  return (
    <div>
        <div className="Slideshow">
          <div className="Slideshow-dots">
              {Array.from({length: Math.ceil(elements.length / nbVisibleElements)}, (x, i) => i).map((_, idx) => (
              <div
                  key={idx}
                  className={`Slideshow-dot${index === idx ? " active" : ""}`}
                  onClick={() => {
                  setIndex(idx);
                  }}
              ></div> 
              ))}
          </div>
              <div
                  className="Slideshow-slider"
                  style={{ transform: `translate3d(${-index * 100}%, 0, 0)`}}
                  onTouchStart={handleTouchStart} 
                  onTouchMove={handleTouchMove} 
                  onTouchEnd={handleTouchEnd} 
              >
                  {Array.from({length: Math.ceil(elements.length / nbVisibleElements)}, (x, i) => i).map((_, slideIdx) => (
                    <div className="Slide"   
                      
                      key={slideIdx} style={{width: "100%"}}>
                        {Array.from({length: nbVisibleElements}, (x, i) => i).map((_, visibleElementIdx) => (
                          <div key={visibleElementIdx} onClick={() => setPrintedComponent(elements[slideIdx * nbVisibleElements + visibleElementIdx])} style={{width: `${100 /nbVisibleElements}%`, display: "inline"}}>
                            {(slideIdx * nbVisibleElements + visibleElementIdx < elements.length )?
                              <Image src={elements[slideIdx * nbVisibleElements + visibleElementIdx].image} style={{width: "inherit"}}></Image> : <></>
                            }
                          </div>
                        ))}
                    </div>
                  ))}
              </div>
        </div>
        {printedComponent.component}
    </div>
  );
}

export default Slideshow;