import { FunctionComponent } from "react";
import { Button, Image } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import ExternalLink from "../core/external-link";

const PhotoDownload: FunctionComponent<any> = ({image, button, downloadFile}) => {
    const { t } = useTranslation();
    return (
        <div>
            <div>
                <Image fluid src={image} rounded />
            </div>
            <div className="mt-2 mb-2">
                <ExternalLink link={downloadFile}><Button variant='light' className=" Button-primary Text-light" type="submit">{t(button)}</Button></ExternalLink>
            </div>
        </div>
    )
}

export default PhotoDownload;