import { FunctionComponent } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const Welcome: FunctionComponent<any> = ({textTop, textBottom}) => {
    const { t } = useTranslation();
    return (
        <div className="App-header">
            <div className="Welcome-content">
                <h1 className="Title-primary text-center">{t(textTop)}</h1>
                <h1 className="Title-primary text-center">{t(textBottom)}</h1>
                <div style={{display: "flex", justifyContent: "center"}} className="p-auto"><Button href="/contact" variant='light' className="m-auto Button-primary Text-light">{t('contact-link')}</Button></div>
                
            </div>
        </div>
    );
  }

export default Welcome;