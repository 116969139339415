import { FunctionComponent, useEffect, useState } from "react";
import ArticleShort from "../components/article-short";
import { Badge } from "react-bootstrap";

const BlogPage: FunctionComponent<any> = () => {
    const [articleList, setArticleList]: any[] = useState([]);
    const [selectedTag, setSelectedTag] = useState(null);
    
    const deleteSelected = () => {
        setSelectedTag(null)
    }

    useEffect(() => {
        fetchData();
      }, [selectedTag]);
    
      const fetchData = async () => {
        try {
            let response = null
            if (selectedTag){
                // response = await fetch(`http://localhost:4000/luciedoret.fr/backend/src/blog.php?tag=${selectedTag}`);
                response = await fetch(`/blog.php?tag=${selectedTag}`);
            } else {
                // response = await fetch(`http://localhost:4000/luciedoret.fr/backend/src/blog.php`);
                response = await fetch(`/blog.php`);
            }
            const jsonData = await response.json();
            setArticleList(jsonData);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
    
    const articles = () => {
        return (
            articleList.map((x: { tags: string | never[]; }, i: number) =>
                (selectedTag === null || x.tags.includes(selectedTag)) ? <div key={i} className="p-2"><ArticleShort key={i} articleShort={x} setSelectedTag={setSelectedTag}/></div>: <div key={i}></div>
              )
        )
    }
    return (
        <div className="w-75 m-auto mt-5">
            <div style={{display: "block", width: "100%", marginTop: "10%"}}></div>
            {(selectedTag) ? <Badge onClick={deleteSelected} className="p-2 m-1" style={{cursor: "pointer"}} bg="secondary">{selectedTag}</Badge>: <></>}
            {articles()}
        </div>
    )
}

export default BlogPage;